<template>
    <option value="" disabled selected>Seleziona il tipo di contratto...</option>
	<option value="Dipendente">Dipendente</option>
	<option value="Libero Professionista">Libero Professionista</option>
	<option value="Convenzionato">Convenzionato</option>
	<option value="Altro">Altro</option>
</template>

<script>
export default {};
</script>
