<template>
    <option value="" disabled selected>Seleziona la disciplina...</option>	
	<option value="Docente iscritto OIDA">Docente iscritto OIDA</option>
	<option value="Allergologia ed immunologia clinica">Allergologia ed immunologia clinica</option>
	<option value="Angiologia">Angiologia</option>
	<option value="Cardiologia">Cardiologia</option>
	<option value="Dermatologia e venereologia">Dermatologia e venereologia</option>
	<option value="Ematologia">Ematologia</option>
	<option value="Endocrinologia">Endocrinologia</option>
	<option value="Gastroenterologia">Gastroenterologia</option>
	<option value="Genetica medica">Genetica medica</option>
	<option value="Geriatria">Geriatria</option>
	<option value="Malattie metaboliche e diabetologia">Malattie metaboliche e diabetologia</option>
	<option value="Malattie dell'apparato respiratorio">Malattie dell'apparato respiratorio</option>
	<option value="Malattie infettive">Malattie infettive</option>
	<option value="Medicina e chirurgia di accettazione e di urgenza">Medicina e chirurgia di accettazione e di urgenza</option>
	<option value="Medicina fisica e riabilitazione">Medicina fisica e riabilitazione</option>
	<option value="Medicina interna">Medicina interna</option>
	<option value="Medicina termale">Medicina termale</option>
	<option value="Medicina aeronautica e spaziale">Medicina aeronautica e spaziale</option>
	<option value="Medicina dello sport">Medicina dello sport</option>
	<option value="Nefrologia">Nefrologia</option>
	<option value="Neonatologia">Neonatologia</option>
	<option value="Neurologia">Neurologia</option>
	<option value="Neuropsichiatria infantile">Neuropsichiatria infantile</option>
	<option value="Oncologia">Oncologia</option>
	<option value="Pediatria">Pediatria</option>
	<option value="Psichiatria">Psichiatria</option>
	<option value="Radioterapia">Radioterapia</option>
	<option value="Reumatologia">Reumatologia</option>
	<option value="Cardiochirurgia">Cardiochirurgia</option>
	<option value="Chirurgia generale">Chirurgia generale</option>
	<option value="Chirurgia maxillo-facciale">Chirurgia maxillo-facciale</option>
	<option value="Chirurgia pediatrica">Chirurgia pediatrica</option>
	<option value="Chirurgia plastica e ricostruttiva">Chirurgia plastica e ricostruttiva</option>
	<option value="Chirurgia toracica">Chirurgia toracica</option>
	<option value="Chirurgia vascolare">Chirurgia vascolare</option>
	<option value="Ginecologia e ostetricia">Ginecologia e ostetricia</option>
	<option value="Neurochirurgia">Neurochirurgia</option>
	<option value="Oftalmologia">Oftalmologia</option>
	<option value="Ortopedia e traumatologia">Ortopedia e traumatologia</option>
	<option value="Otorinolaringoiatria">Otorinolaringoiatria</option>
	<option value="Urologia">Urologia</option>
	<option value="Anatomia patologica">Anatomia patologica</option>
	<option value="Anestesia e rianimazione">Anestesia e rianimazione</option>
	<option value="Biochimica clinica">Biochimica clinica</option>
	<option value="Farmacologia e tossicologia clinica">Farmacologia e tossicologia clinica</option>
	<option value="Laboratorio di genetica medica">Laboratorio di genetica medica</option>
	<option value="Medicina trasfusionale">Medicina trasfusionale</option>
	<option value="Medicina legale">Medicina legale</option>
	<option value="Medicina nucleare">Medicina nucleare</option>
	<option value="Microbiologia e virologia">Microbiologia e virologia</option>
	<option value="Neurofisiopatologia">Neurofisiopatologia</option>
	<option value="Neuroradiologia">Neuroradiologia</option>
	<option value="Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)">Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)</option>
	<option value="Radiodiagnostica">Radiodiagnostica</option>
	<option value="Igiene, epidemiologia e sanità pubblica">Igiene, epidemiologia e sanità pubblica</option>
	<option value="Igiene degli alimenti e della nutrizione">Igiene degli alimenti e della nutrizione</option>
	<option value="Medicina del lavoro e sicurezza degli ambienti di lavoro">Medicina del lavoro e sicurezza degli ambienti di lavoro</option>
	<option value="Medicina generale (medici di famiglia)">Medicina generale (medici di famiglia)</option>
	<option value="Continuità assistenziale">Continuità assistenziale</option>
	<option value="Pediatria (pediatri di libera scelta)">Pediatria (pediatri di libera scelta)</option>
	<option value="Igiene degli allevamenti e delle produzioni zootecniche">Igiene degli allevamenti e delle produzioni zootecniche</option>
	<option value="Igiene prod., trasf., commercial., conserv. E tras. Alimenti di origine animale e derivati">Igiene prod., trasf., commercial., conserv. E tras. Alimenti di origine animale e derivati</option>
	<option value="Sanità animale">Sanità animale</option>
	<option value="Odontoiatria">Odontoiatria</option>
	<option value="Farmacia ospedaliera">Farmacia ospedaliera</option>
	<option value="Farmacia territoriale">Farmacia territoriale</option>
	<option value="Biologo">Biologo</option>
	<option value="Chimica analitica">Chimica analitica</option>
	<option value="Psicoterapia">Psicoterapia</option>
	<option value="Psicologia">Psicologia</option>
	<option value="Fisica sanitaria">Fisica sanitaria</option>
	<option value="Assistente sanitario">Assistente sanitario</option>
	<option value="Dietista">Dietista</option>
	<option value="Fisioterapista">Fisioterapista</option>
	<option value="Educatore professionale">Educatore professionale</option>
	<option value="Igienista dentale">Igienista dentale</option>
	<option value="Infermiere">Infermiere</option>
	<option value="Infermiere pediatrico">Infermiere pediatrico</option>
	<option value="Logopedista">Logopedista</option>
	<option value="Ortottista/assistente di oftalmologia">Ortottista/assistente di oftalmologia</option>
	<option value="Ostetrica/o">Ostetrica/o</option>
	<option value="Podologo">Podologo</option>
	<option value="Tecnico della riabilitazione psichiatrica">Tecnico della riabilitazione psichiatrica</option>
	<option value="Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare">Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare</option>
	<option value="Tecnico sanitario laboratorio biomedico">Tecnico sanitario laboratorio biomedico</option>
	<option value="Tecnico sanitario di radiologia medica">Tecnico sanitario di radiologia medica</option>
	<option value="Tecnico audiometrista">Tecnico audiometrista</option>
	<option value="Tecnico audioprotesista">Tecnico audioprotesista</option>
	<option value="Tecnico di neurofisiopatologia">Tecnico di neurofisiopatologia</option>
	<option value="Tecnico ortopedico">Tecnico ortopedico</option>
	<option value="Terapista della neuro e psicomotricità dell'età evolutiva">Terapista della neuro e psicomotricità dell'età evolutiva</option>
	<option value="Terapista occupazionale">Terapista occupazionale</option>
	<option value="Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro">Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro</option>
	<option value="Scienza dell'alimentazione e dietetica">Scienza dell'alimentazione e dietetica</option>
	<option value="Direzione medica di presidio ospedaliero">Direzione medica di presidio ospedaliero</option>
	<option value="Organizzazione dei servizi sanitari di base">Organizzazione dei servizi sanitari di base</option>
	<option value="Audiologia e foniatria">Audiologia e foniatria</option>
	<option value="Privo di specializzazione">Privo di specializzazione</option>
	<option value="Cure palliative">Cure palliative</option>
	<option value="Epidemiologia">Epidemiologia</option>
	<option value="OSS">OSS</option>
	<option value="Altro">Altro</option>
</template>

<script>
export default {};
</script>
