<template>
    
    <section aria-labelledby="payment-and-shipping-heading" class="py-8 md:py-16 lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1 lg:col-end-7">
        <h2 id="payment-and-shipping-heading" class="sr-only">Payment and shipping details</h2>

        <form v-on:submit.prevent>
          <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">

            <div class="border rounded px-4 py-4 mb-10">
                <div class="grid grid-cols-6 gap-4 border-b pb-4 items-start">
                    <!-- <p class="col-start-1 text-sm text-gray-600">Contatto</p> -->
                    <p class="col-start-1 col-end-5 text-sm text-gray-900">{{user.email}}</p>
                    <router-link to="Step1" class="col-start-6 text-xs text-gray-900 justify-self-end">
                    <a href="#">Cambia</a>
                    </router-link>
                </div>
                <div class="grid grid-cols-6 gap-4 pt-4 items-start">
                    <!-- <p class="col-start-1 text-sm text-gray-600">Informazioni</p> -->
                    <p class="col-start-1 col-end-5 text-sm text-gray-900">{{user.nome}} {{user.cognome}} {{user.telefono}} </p>
                    <router-link to="Step1" class="col-start-6 text-xs text-gray-900 justify-self-end">
                    <a href="#">Cambia</a>
                    </router-link>
                </div>
            </div>

            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Informazioni aggiuntive</h3>

                <div class="mt-6">
                    <div class="mt-1">
                    <input type="text" id="codice-fiscale" placeholder="Codice fiscale (obbligatorio)" v-model="user.codicefiscale" @input="setUser('codicefiscale', $event.target.value)" class="uppercase block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.codicefiscale }" />
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.codicefiscale">Questo campo è obbligatorio</span>
                    </div>
                </div>                
            </div>

            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Professione</h3>
              <div class="mt-6">
                    <div class="mt-1">
                        <select id="professione" name="professione" v-model="user.professione" @input="setUser('professione', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" placeholder="Professione"  :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.professione }">
                          <Professione />
                        </select>
                        <span class="pt-2 text-red-600 text-sm" v-if="errors.professione ">Questo campo è obbligatorio</span>
                    </div>
                </div>
            </div>
            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Disciplina</h3>
              <div class="mt-6">
                    <div class="mt-1">
                        <select id="disciplina" name="disciplina" v-model="user.disciplina" @input="setUser('disciplina', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" placeholder="Disciplina" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.disciplina }">
                          <Disciplina />
                        </select>
                        <span class="pt-2 text-red-600 text-sm" v-if="errors.disciplina ">Questo campo è obbligatorio</span>
                    </div>
                </div>
            </div>
            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Forma contrattuale</h3>
              <div class="mt-6">
                    <div class="mt-1">
                        <select id="fattura" name="fattura" v-model="user.contratto" @input="setUser('contratto', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" placeholder="Contratto"  :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.contratto }">
                          <Contratto />
                        </select>
                        <span class="pt-2 text-red-600 text-sm" v-if="errors.contratto ">Questo campo è obbligatorio</span>
                    </div>
                </div>
            </div>

            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Richiesta fattura</h3>
              <div class="mt-6">
                    <div class="mt-1">
                        <select id="fattura" name="fattura" v-model="user.fattura" @input="setUser('fattura', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" placeholder="Richiesta fattura">
                          <option value="no" selected>No</option>
                          <option value="yes">Sì</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="mt-10" v-if="user.fattura == 'yes' ">
              <h3 id="shipping-heading" class="text-lg font-medium text-gray-900">Indirizzo di fatturazione</h3>

              <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
                                
                <div class="sm:col-span-6">                  
                  <div class="mt-1">
                    <input type="text" id="indirizzo" name="indirizzo" v-model="user.ragionesociale" @input="setUser('ragionesociale', $event.target.value)" placeholder="Ragione Sociale (OPZIONALE)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />                    
                  </div>
                </div>

                <div class="sm:col-span-6">                  
                  <div class="mt-1">
                    <input type="text" id="indirizzo" name="indirizzo" v-model="user.piva" @input="setUser('piva', $event.target.value)" placeholder="PIVA/CodiceFiscale" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.piva }"/>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.piva ">Questo campo è obbligatorio</span>
                  </div>
                </div>
                
                <div class="sm:col-span-6">                  
                  <div class="mt-1">
                    <input type="text" id="indirizzo" name="indirizzo" v-model="user.indirizzo" @input="setUser('indirizzo', $event.target.value)" placeholder="Indirizzo e numero civico" autocomplete="street-address" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.indirizzo }"/>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.indirizzo ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-2">                  
                  <div class="mt-1">
                    <input type="text" id="cap" name="cap" v-model="user.cap" placeholder="CAP" @input="setUser('cap', $event.target.value)" autocomplete="postal-code" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.cap }"/>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.cap ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-2">                  
                  <div class="mt-1">
                    <input type="text" id="city" name="city" v-model="user.citta" @input="setUser('citta', $event.target.value)" placeholder="Città" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.citta }"/>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.citta ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <!-- <label for="provincia" class="block text-sm font-medium text-gray-700">Provincia</label> -->
                  <div class="mt-1">                    
                    <select id="provincia" name="provincia" v-model="user.provincia" @input="setUser('provincia', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" placeholder="Provincia" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.provincia }">
                      <Province />
                    </select>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.provincia ">Questo campo è obbligatorio</span>
                  </div>
                </div>  

                <div class="sm:col-span-6">
                    <div class="mt-1">
                    <input type="text" id="pec" placeholder="PEC o Codice Univoco" v-model="user.pec" @input="setUser('pec', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.pec }" />
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.pec ">Questo campo è obbligatorio</span>
                    </div>
                </div>                              
                
              </div>
            </div> 

            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Scelta dei corsi</h3>
              <h4 id="subcontact-info-heading" class="text-sm text-gray-600 mt-1">Scegli i corsi da attivare nel tuo profilo per un totale massimo degli ECM inclusi con la tua Sanifad Card.</h4>              
                <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
                    <div v-for="course in courses.corsi" :key="course.id" class="sm:col-span-2">
                        <div class="">
                            <div class="flex flex-col gap-x-2 items-center">
                                <div class="bg-green-800 origin-top w-full font-medium text-center text-white">
                                    {{course.ecm}} ECM
                                </div>
                                <img :src="'https://sanistrapi.caprover.shopifive.com' + course.thumbnail[0].url" :alt="course.thumbnail[0].name" class="flex-none w-100 h-50 object-center object-cover" />                            
                                <!-- <p class="text-sm font-semibold">{{course.Titolo}}</p> -->
                                <div class="flex w-full">
                                    <a v-show="!this.checkSelected(course.id)" v-on:click="selezionaCorso(course.id)" target="_blank" class="cursor-pointer flex justify-center items-center py-2 w-full button bg-yellow-400 bg-opacity-80 border border-transparent text-sm font-medium text-black text-center hover:bg-yellow-500 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-900">SELEZIONA CORSO</a>
                                    <a v-show="this.checkSelected(course.id)" v-on:click="rimuoviCorso(course.id)" target="_blank" class="cursor-pointer flex justify-center items-center py-2 w-full button bg-red-600 bg-opacity-80 border border-transparent text-sm font-medium text-white text-center hover:bg-red-700 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-900">RIMUOVI CORSO</a>
                                    <a :href="course.link" target="_blank" class="py-2 w-full button bg-green-600 bg-opacity-80 border border-transparent text-sm font-medium text-white text-center hover:bg-green-500 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-900">VISUALIZZA BROCHURE</a>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
              
            </div>          
            
            <div class="mt-10 pb-12">
              <button type="submit" v-on:click="stepControl()" :disabled="loading" class="button--big bg-green-600 bg-opacity-80 border border-transparent rounded-md text-sm font-medium text-white hover:bg-green-500 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-900">
                <div v-if="loading">
                  Stiamo inviando i dati...
                </div>
                <div v-if="!loading">
                  Completa ordine
                </div>
                </button>
              <router-link to="Step1">
                <a href="#" class="text-sm text-white pl-6">Torna alle informazioni</a>
              </router-link>
            </div>

            <BottomMenu />

          </div>
        </form>
             
    </section>


</template>

<script>
import BottomMenu from './BottomMenu.vue';
import { computed } from 'vue';
import { state, setUser } from './User';
import { state as product } from './Product';
import { state as courses, setSelezionati } from './Course';
import Province from './Province.vue';
import Disciplina from './Disciplina.vue';
import Professione from './Professione.vue';
import Contratto from './Contratto.vue';
import axios from 'axios';
import { useToast } from "vue-toastification";
import CodiceFiscale from 'codice-fiscale-js';
var generator = require('generate-password');

const user = computed({
    get: () => state.user,    
});

// const prezzo = computed({
//     get: () => product.prezzo,    
// });

const shopifyId = computed({
    get: () => product.shopifyId,    
});

let ecmTotal = 0;
const toast = useToast();    

export default {
    setup() {
        return {
            user,
            shopifyId,
            setUser,
            Province,
            Disciplina,
            Professione,
            Contratto,
            courses
        }
    },
    data() {
        return {
            errors : {},
            loading: false
        }
    },
    methods: {
        stepControl: async function() {
            let hasErrors = false;      
            // if(state.user.codicefiscale == "" || typeof state.user.codicefiscale == "undefined"){
            //     this.errors.codicefiscale = true;
            //     hasErrors = true;
            // } else {
            //     this.errors.codicefiscale = false;
            //     hasErrors = false;
            // }
            // if(state.user.professione == "" || typeof state.user.professione == "undefined"){                
            //     this.errors.professione = true;
            //     hasErrors = true;
            // } else {
            //     this.errors.professione = false;
            //     hasErrors = false;
            // }
            // if(state.user.disciplina == "" || typeof state.user.disciplina == "undefined"){                
            //     this.errors.disciplina = true;
            //     hasErrors = true;
            // } else {
            //     this.errors.disciplina = false;
            //     hasErrors = false;
            // }
            // if(state.user.contratto == "" || typeof state.user.contratto == "undefined"){                
            //     this.errors.contratto = true;
            //     hasErrors = true;
            // } else {
            //     this.errors.contratto = false;
            //     hasErrors = false;
            // }
            const requiredFields = [
                'codicefiscale',
                'professione',
                'disciplina',
                'contratto'
            ]
            requiredFields.forEach(field => {
                this.errors[field] = !state.user[field] ? true : false;
            })

            if(state.user.fattura == "yes"){                
                for (const [key, value] of Object.entries(state.user)) {
                    !value && !['nome','cognome','email', 'telefono','card', 'fattura','submitted', 'ragionesociale'].includes(key) ? hasErrors = true : null;
                    this.errors[key] = !value ? true : false;
                }                                
            }    
            // let errors = this.errors.filter(item => item == true);
            let errors = Object.entries(this.errors).filter(function([key, value]){                
                if(requiredFields.includes(key)){
                    return value === true;
                }                
            });            
            if(errors.length > 0){
                toast.error("Per favore compila tutti i campi obbligatori!");
                return;
            }
            
            if(hasErrors){
                toast.error("Per favore compila tutti i campi obbligatori!");
                return;
            }
            if(courses.selezionati.length <= 0){
                toast.error("Attenzione, devi selezionare almeno un corso da attivare!");
                return;
            }

            if(state.user.codicefiscale.length != 16){
                toast.error("Attenzione, il codice fiscale non risulta corretto! Controlla anche spazi bianchi.");
                return;
            }

            var cf = new CodiceFiscale(state.user.codicefiscale);
            let datiCF = cf.toJSON();            
            let _cfValid = CodiceFiscale.check(state.user.codicefiscale);
            
            if(!_cfValid){
                toast.error("Attenzione, il codice fiscale non risulta corretto!");
                return;
            }

            let password = generator.generate({
                length: 10,
                numbers: true,
                uppercase: true,
                lowercase: true,
                symbols: true,
                strict: true,
                exclude: '~:][{}/|+-`();,<>^=+-_."',
                excludeSimilarCharacters: true
            });

            let telefono = state.user.telefono != null ? state.user.telefono : "+393519758817";
            
            this.loading = true;
            if(state.user.card == 'Card per professionisti sanitari da 150 ECM'){
                try {
                    await axios.post(`https://attivazioni.sanifad.com`,{                    
                        user : {
                            "email": state.user.email,
                            "password": password,
                            "nome": state.user.nome,
                            "cognome": state.user.cognome,
                            "citta": state.user.citta,
                            "luogo_nascita": datiCF.birthplace,
                            "giorno_nascita": datiCF.day,
                            "mese_nascita": datiCF.month,
                            "anno_nascita": datiCF.year,
                            "cap": state.user.cap,
                            "indirizzo": state.user.indirizzo,
                            "telefono": telefono,
                            "codicefiscale": state.user.codicefiscale,
                            "professione": state.user.professione,
                            "disciplina": state.user.disciplina,
                            "contratto": state.user.contratto
                        }                
                    });
                    await axios.post(`https://sanistrapi.caprover.shopifive.com/credenzialis`,{                    
                        "username": state.user.email,
                        "password": password,
                        "link": "https://www.ecmjmideas.it/login/index.php",
                        "fornitore": "IDEAS",
                        "attivazioni": `${this.$route.params.id}`
                    });
                } catch (error) {
                    console.log(error);
                    alert("C'è stato un errore, per favore scrivi una email a info@sanifad.com");
                    this.loading = false;
                }   
            }                     
            
            try {                
                await axios.put(`https://sanistrapi.caprover.shopifive.com/attivazionis/${this.$route.params.id}`,{                    
                    "nome": state.user.nome,
                    "cognome": state.user.cognome,
                    "email": state.user.email,
                    "indirizzo": state.user.indirizzo,
                    "cap": state.user.cap,
                    "citta": state.user.citta,
                    "provincia": state.user.provincia,
                    "telefono": state.user.telefono,
                    "codicefiscale": state.user.codicefiscale,
                    "ragionesociale": state.user.ragionesociale,
                    "piva": state.user.piva,
                    "pec": state.user.pec,
                    "fattura": state.user.fattura,
                    "professione": state.user.professione,
                    "disciplina": state.user.disciplina,
                    "contratto": state.user.contratto,
                    "corsis": courses.selezionati.map(a => a.id)                
                });                
                toast.success("Dati aggiornati correttamente");
                setUser('submitted', "yes");        
                await axios.post(`https://san8nfad.caprover.shopifive.com/webhook/7fe857f5-a192-43f0-8dfb-c6dd81d9dd7f`, {
                    "acquisto": this.$route.params.id
                });
                this.$router.push({ name: 'thank-you', params: {id: this.$route.params.id }});    
            } catch (error) {
                console.log(error);
                alert("C'è stato un errore, per favore scrivi una email a info@sanifad.com");
                this.loading = false;
            }            
        },
        selezionaCorso: function(e){
            if(!this.puoiAggiungere(e)){
                toast.error("Hai raggiunto il numero massimo di ECM compresi nella tua Sanifad Card!");
                return;
            }
            if(!this.checkSelected(e)){
                let corso = courses.corsi.filter( item => item.id == e);
                setSelezionati(courses.selezionati.concat(corso))                
                toast.success("Il corso è stato aggiunto correttamente");
            }            
        },
        rimuoviCorso: function(id){
            setSelezionati(courses.selezionati.filter( item => item.id != id))
            toast.success("Il corso è stato rimosso correttamente");
        },
        checkSelected: function(id){
            return courses.selezionati.filter(item => item.id == id).length > 0 ? true : false;                
        },
        puoiAggiungere: function(id){            
            let corso = courses.corsi.filter( item => item.id == id)[0];            
            switch (state.user.card) {
                case "Card per professionisti sanitari da 50 ECM":
                    ecmTotal = 50;
                    break;
                case "Card per professionisti sanitari da 150 ECM":
                    ecmTotal = 150;
                    break;
                default:
                    break;
            }            
            return this.cartTotal + corso.ecm > ecmTotal ? false : true;
        }
    },
    computed: {
        cartTotal: function() {
            return courses.selezionati.reduce((acc, item) => {
                return acc + parseFloat(item.ecm);
            }, 0);
        }    
    },    
    mounted() {             
        !state.user.email ? this.$router.push('step1') : null;
        state.user.submitted == "yes" ? this.$router.push({ name: 'thank-you', params: {id: this.$route.params.id }}) : null
    },
    components: {
        BottomMenu,
        Province,
        Disciplina,
        Professione,
        Contratto
    }
}

</script>