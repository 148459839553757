<template>
    <option value="" disabled selected>Seleziona la professione...</option>
    <option value="Docente iscritto OIDA">Docente iscritto OIDA</option>
	<option value="Medico chirurgo">Medico chirurgo</option>
	<option value="Odontoiatra">Odontoiatra</option>
	<option value="Farmacista">Farmacista</option>
	<option value="Veterinario">Veterinario</option>
	<option value="Psicologo">Psicologo</option>
	<option value="Biologo">Biologo</option>
	<option value="Chimico">Chimico</option>
	<option value="Fisico">Fisico</option>
	<option value="Assistente sanitario">Assistente sanitario</option>
	<option value="Dietista">Dietista</option>
	<option value="Educatore professionale">Educatore professionale</option>
	<option value="Fisioterapista">Fisioterapista</option>
	<option value="Igienista dentale">Igienista dentale</option>
	<option value="Infermiere">Infermiere</option>
	<option value="Infermiere pediatrico">Infermiere pediatrico</option>
	<option value="Logopedista">Logopedista</option>
	<option value="Ortottista/assistente di oftalmologia">Ortottista/assistente di oftalmologia</option>
	<option value="Ostetrica/o">Ostetrica/o</option>
	<option value="Podologo">Podologo</option>
	<option value="Tecnico audiometrista">Tecnico audiometrista</option>
	<option value="Tecnico audioprotesista">Tecnico audioprotesista</option>
	<option value="Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare">Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare</option>
	<option value="Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro">Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro</option>
	<option value="Tecnico della riabilitazione psichiatrica">Tecnico della riabilitazione psichiatrica</option>
	<option value="Tecnico di neurofisiopatologia">Tecnico di neurofisiopatologia</option>
	<option value="Tecnico ortopedico">Tecnico ortopedico</option>
	<option value="Tecnico sanitario di radiologia medica">Tecnico sanitario di radiologia medica</option>
	<option value="Tecnico sanitario laboratorio biomedico">Tecnico sanitario laboratorio biomedico</option>
	<option value="Terapista della neuro e psicomotricità dell'età evolutiva">Terapista della neuro e psicomotricità dell'età evolutiva</option>
	<option value="Terapista occupazionale">Terapista occupazionale</option>
	<option value="OSS">OSS</option>
	<option value="Altro">Altro</option>
</template>

<script>
export default {};
</script>
